import React from "react";

import { createTheme } from "@mui/material/styles";
import { History } from "../history";
import PackageAllocationsTable from "./tables/package_allocations_table";
import { Illustration } from "/components/images/svgs";
import _ from "lodash";
import { BalanceHistory } from "../balance_history";

const Theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

const TableRowDetails = ({ row, showCards = true }) => {
  const download = () => {
    const url = row.original.download_path;
    window.open(url, "_blank");
  };

  if (!row)
    return (
      <>
        <div className="section">Medical Claim Information</div>
        <div className="flex justify-center flex-col" style={{ height: "calc(100vh - 340px)" }}>
          <Illustration width={178} />
          <div className="t--sm t--upcase t--center mt--8 mb--8">No Claim Selected</div>
        </div>
      </>
    );

  const renderIssues = () => {
    if (_.isEmpty(row.original.issues)) {
      return (
        <div className="list__item list__item--disable-hover">
          <div className="t--sm t--italic t--subtitle">No issues</div>
        </div>
      );
    }

    return row.original.issues?.map((issue, index) => (
      <div className="list__item list__item--disable-hover">
        <div className="container container--align-center">
          <div className="indicator indicator--red"></div>
          <div className="t--subtitle t--sm">Issue</div>
        </div>
        <div className="t--sm">{issue.label}</div>
      </div>
    ));
  };

  return (
    <>
      {showCards && (
        <>
          <div className="button button--outlined button--fw" onClick={download}>
            <div className="material-symbols-outlined">download</div>
            Download Claim
          </div>
          <div className="section">Medical Claim Information</div>
          <div className="list__item list__item--disable-hover">
            <div className="t--subtitle t--sm">Date of Service</div>
            <div className="t--sm">{row.original.date_of_service}</div>
          </div>
          <div className="list__item list__item--disable-hover">
            <div className="t--subtitle t--sm">Plan</div>
            <div className="t__stack">
              <div className="t--sm t--right">{row.original.plan_name}</div>
              <div className="t--sm t--light t--right">{row.original.plan_id}</div>
            </div>
          </div>

          <div className="list__item list__item--disable-hover">
            <div className="t--subtitle t--sm">Claim Number</div>
            <div className="t--sm">{row.original.claim_number?.substring(0,12)}</div>
          </div>
          <div className="list__item list__item--disable-hover">
            <div className="t--subtitle t--sm">Balance</div>
            <div className="t--sm">{row.original.balance}</div>
          </div>
          <div className="list__item list__item--disable-hover">
            <div className="t--subtitle t--sm">Facility</div>
            <div className="t__stack">
              <div className="t--sm t--right">{row.original.pharmacy.name}</div>
              <div className="t--sm t--light t--right">{row.original.pharmacy.address}</div>
            </div>
          </div>
          <div className="section">Allocations</div>
          {row.original.allocations ? (
            <PackageAllocationsTable data={row.original.allocations} />
          ) : (
            <div className="t--sm t--light">No allocations</div>
          )}
          <div className="section">Issues</div>
          {renderIssues()}
          <div className="section">Balance History</div>
          <BalanceHistory balance_history_path={row.original.balance_history_path} />
        </>
      )}

      <History history_path={row.original.history_path} />
    </>
  );
};

export default TableRowDetails;
