import React, { useRef, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import ClaimDetailsTable from "./table";
import Notice from "/components/shared/Notice";
import Http from "../http";
import Filters from "/components/shared/filters";
import TableRowDetails from "./table_row_details";
import { stringify } from "qs";

const MedicalReport = (props) => {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState(props.filters);
  const [idFilter, setIdFilter] = useState([]);
  const [claims, setClaims] = useState([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const isMounted = useRef(false);

  useEffect(() => {
    const controller = new AbortController();

    setIsClaimsLoading(true);
    var params = stringify(filters);
    history.pushState(filters, "", "/claims/reports/medical?" + params);
    Http.newPostQuery(props.query_path, { ...filters, ids: idFilter }, controller.signal).then((res) => {
      setClaims(res.claims);
      setIsClaimsLoading(false);
    });

    return () => {
      controller.abort();
    };
  }, [filters, idFilter]);

  const download = () => {
    const url = props.download_path + "?" + stringify(filters);
    window.open(url, "_blank");
  };

  useEffect(() => {
    if ((isMounted.current && !claims) || (isMounted.current && claims.length == 0)) {
      setNotice({
        kind: "success",
        open: true,
        message: "The current selection and filters returned no results",
      });
    }

    isMounted.current = true;
  }, [claims]);

  const claimView = (tab) => {
    return (
      <div key={tab} className="tabs__view tabs__view--active" data-tab-name={tab}>
        <ClaimDetailsTable claims={claims} isLoading={isClaimsLoading} handleRowClick={setSelectedRow} />
      </div>
    );
  };

  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Medical Claims Report</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Filters
            kind="medical"
            filterCallback={setFilters}
            idFilterCallback={setIdFilter}
            defaultFilters={props.filters}
            filterLists={props.filterLists}
            download={download}
          />
        </div>
      </div>
      <div className="body__content">
        <div className="panel panel--pad panel--no-btm">{claimView()}</div>
        <div className="panel panel--info panel--pad panel--no-btm">
          <TableRowDetails row={selectedRow} />
        </div>
      </div>
      <Notice details={notice} />
    </>
  );
};

export default MedicalReport;
