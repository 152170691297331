import React, { useEffect, useState } from "react";
import Http from "../http";
import AccountsTable from "../tables/accounts_table";

const Header = ({path}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData(null);
    setIsLoading(true);
    Http.newFetchQuery(path).then((res) => {
      setData(res.data);
      setIsLoading(false);
    });
  }, [path]);

  return (
    <>
        <div className="container container--space-between">
          <div className="container container--columns container--shrink">
            <div className="t--light t--subtitle">{data?.pharmacy}</div>
            <div className="t__stack">
              <div className="t__stack__title">{data ? data.pharmacy_name : "No Eligible Accounts"}</div>
              <div className="t__stack__subtitle">
                {data ? data.pharmacy_address : "You have no available data to view at this time."}
              </div>
            </div>
          </div>
          <div className="container container--gap-lg" style={{ marginBottom: 10 }}>
            <div className="t__stack">
              <div className="t__stack__subtitle t--right">Claim Balance</div>
              <div className="t--lg t__stack__title t--right">{data ? data.balance : "0.0"}</div>
            </div>
            <div className="t__stack">
              <div className="t__stack__subtitle t--right">Package Balance</div>
              <div className="t--lg t__stack__title t--right">{data ? data.not_filled : "0"}</div>
            </div>
            <div className="t__stack">
              <div className="t__stack__subtitle t--right">Credit Balance</div>
              <div className="t--lg t__stack__title t--right">{data ? data.credit_balance : "$0.00"}</div>
            </div>
          </div>
        </div>
        <div className="container container--end container--mb-md">
          <a href={data?.download_link} download={`account_${data?.id}.xlsx`}>
            <button className={`button button--outlined ${data ? "" : "button--outlined--disabled"}`}>
              <div className="material-symbols-outlined">download</div>
              Download Report
            </button>
          </a>
        </div>
    </>
  );
}

export default Header;