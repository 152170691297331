import React from "react";

export const PaginationControls = ({ table }) => {
  return (
    <div className="flex__container" style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
      <button
        className="button button--small"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        Prev
      </button>
      <button
        className="button button--small"
        style={{ marginLeft: 10 }}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        Next
      </button>
    </div>
  );
};
