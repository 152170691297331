import React, { useEffect, useState } from "react";
import _ from "lodash";

const QuantityModal = ({ title, purchase, open, setOpen, action }) => {
  const [newQuantity, setNewQuantity] = useState(-1);
  const [checked, setChecked] = useState(false);
  const [valid, setValid] = useState(false);

  const handleCancel = () => {
    setValid(false);
    setNewQuantity(-1);
    setChecked(false);
    setOpen(false);
  };

  useEffect(() => {
    // Check quantity greater than minimun
    if (newQuantity >= purchase.minimum_quantity) {
      // check confirmation checkbox
      if (checked) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else {
      setValid(false);
    }
  }, [checked, newQuantity]);

  const handleConfirm = () => {
    action(purchase.reverse_path, newQuantity);
    setOpen(false);
  };

  const handleInput = (e) => {
    setNewQuantity(e.target.value);
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const renderHeader = () => {
    return (
      <>
        <div className="modal__header">
          <div className="t__stack">
            <div className="t__stack__title t--lg">{title}</div>
          </div>
        </div>
      </>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal__body" style={{ minHeight: 100 }}>
        <div>
          <p>
            Current package quantity:
            <strong>{" " + purchase.package_quantity}</strong> <br /> <br />
            Reversed package quantity:&ensp;
            <input
              className="inputs"
              style={{ width: "50px", height: "30px" }}
              type="number"
              onInput={(e) => handleInput(e)}
            />
            <p
              style={{ "margin-top": "10px" }}
              id="quantity-error"
              className="t--sm t--bold"
            >
              New quantity cannot be less than the current filled quantity (
              {purchase.minimum_quantity})
            </p>
          </p>
          <br /> <br />
        </div>
        <div className="flex flex--dir-col container--gap-md">
          <div className="flex flex--justify-center">
            <input
              id="confirm-checkbox"
              type="checkbox"
              onInput={(e) => handleCheck(e)}
            />
            <label for="confirm-checkbox">
              &ensp;
              <strong>
                I confirm that I want the purchase quantity to change
              </strong>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer flex--space-between">
        <button
          className="button button--outlined"
          onClick={() => handleCancel()}
        >
          Cancel
        </button>
        {valid ? (
          <button className="button" onClick={() => handleConfirm()}>
            Confirm
          </button>
        ) : (
          <button className="button" disabled>
            Confirm
          </button>
        )}
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onClick={(e) => {
            e.target.className == "modal__background" && handleDismiss();
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
          </div>
        </div>
      );
    }
  };

  return <>{renderModal()}</>;
};

export default QuantityModal;
