import _ from "lodash";
import moment from "moment";
import debounce from "debounce-promise";
import axios from "axios";
import fs from "fs";

export default class Http {
  getQueries = (searchQuery) => {
    var queries = {};

    _.each(searchQuery, (value, key) => {
      if (value && value.length) _.assign(queries, { [key]: value });
    });

    return queries;
  };

  static newFetchQuery = (path, searchQuery, signal) => {
    axios.params = searchQuery;
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
          },
          params: {
            ...searchQuery,
          },
          signal: signal,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  static newPostQuery = (path, searchQuery, signal) => {
    //axios.params = searchQuery;
    return new Promise((resolve, reject) => {
      axios
        .post(path, searchQuery, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
          },
          signal: signal,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  static download = (path, searchQuery) => {
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          responseType: "blob",
          headers: {
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
          },
          params: {
            ...searchQuery,
          },
        })
        .then((response) => {
          const filename = response.headers["content-disposition"].split("filename=")[1];
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  static fetchQuery = (path, searchQuery) => {
    return axios
      .get(path, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        params: {
          ...searchQuery,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {});
  };

  static postData = (path, body) => {
    return axios
      .post(path, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {});
  };
}
