import React, { useState, useEffect } from "react";
import Filters from "/components/shared/filters";
import Http from "../http";
import { stringify } from "qs";
import AccountsTable from "../tables/accounts_table";
import AccountsPackagesTable from "../tables/accounts_packages_table";
import AccountsCreditsTable from "../tables/accounts_credits_table";
import PackageAllocationsTable from "../tables/package_allocations_table";
import ClaimAllocationsTable from "../tables/claim_allocations_table";
import CardSkeleton from "../util/card_skeleton";
import { Illustration } from "/components/images/svgs";
import Tabs from "/components/shared/tabs";
import _, { set } from "lodash";
import Claims from "../components/claims";
import Packages from "../components/packages";
import Credits from "../components/credits";
import Header from "../components/header";

const PurchaseReport = (props) => {
  const [selected, setSelected] = useState(null);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [selectedClaimIndex, setSelectedClaimIndex] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(null);
  const [selectedCredit, setSelectedCredit] = useState(null);
  const [selectedCreditIndex, setSelectedCreditIndex] = useState(null);
  const [selectedTab, setSelectedTab] = useState(1);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState(props.filters);
  const [idFilter, setIdFilter] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    { id: 1, label: "Claims" },
    { id: 2, label: "Packages" },
    { id: 3, label: "Credits" },
  ];

  const download = () => {
    //Http.download(props.download_path, filters);
    const url = props.download_path + "?" + stringify(filters);
    window.open(url, "_blank");
  };

  const renderTabs = () => {
    return <Tabs items={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />;
  };

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case 1:
        return renderClaims();
      case 2:
        return renderPackages();
      case 3:
        return renderCredits();
      default:
        return renderClaims();
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);

    setSelectedCredit(null);
    setSelectedPackage(null);
    setSelectedClaim(null);
    var params = stringify(filters);
    history.pushState(filters, "", "/accounts/accounts?" + params);
    Http.newPostQuery(props.query_path, { ...filters, ids: idFilter }, controller.signal).then((res) => {
      setData(res.data);
      setIsLoading(false);
    });

    return () => {
      controller.abort();
    };
  }, [filters, idFilter]);

  useEffect(() => {
    setSelected(data[0]);
  }, [data]);

  const renderHeader = () => {
    // if (selected == null) {
    //   return <div className="t--sm">Please select an account</div>;
    // }

    return (
      <>
        <Header path={selected?.stats_path} />
      </>
    );
  };

  const getIndicatorColor = (status) => {
    switch (status?.toLowerCase()) {
      case "paid":
        return "green";
      case "under review":
        return "orange";
      case "filled":
        return "green";
      case "allocated":
        return "green";
      case "reversed":
        return "red";
      default:
        return "blue";
    }
  };

  const renderCards = () => {
    if (_.isEmpty(data) && isLoading) {
      return <CardSkeleton rows={9} />;
    }

    return data?.map((account, index) => {
      return (
        <div
          onClick={() => setSelected(account)}
          className={
            account.id == selected?.id ? "list__item flex--dir-col list__item--selected" : "list__item flex--dir-col"
          }
        >
          <div className="list__item__header">{account.manufacturer}</div>
          <div className="list__item__body  t--upcase">{account.product_name}</div>
          <div className="list__item__footer">
            <div>{account.ndc}</div>
            <div>{account.pharmacy}</div>
          </div>
        </div>
      );
    });
  };

  const renderClaims = () => {
    return (
      <Claims
        path={selected?.claims_path}
        isParentLoading={isLoading}
        setSelectedClaim={setSelectedClaim}
        selectedClaimIndex={selectedClaimIndex}
        setSelectedClaimIndex={setSelectedClaimIndex}
      />
    );
  };

  const renderPackages = () => {
    return (
      <Packages
        path={selected?.packages_path}
        isParentLoading={isLoading}
        setSelectedPackage={setSelectedPackage}
        selectedPackageIndex={selectedPackageIndex}
        setSelectedPackageIndex={setSelectedPackageIndex}
      />
    );
  };

  const renderCredits = () => {
    return (
      <Credits
        path={selected?.credits_path}
        isParentLoading={isLoading}
        setSelectedCredit={setSelectedCredit}
        selectedCreditIndex={selectedCreditIndex}
        setSelectedCreditIndex={setSelectedCreditIndex}
      />
    );
  };

  const renderDetailsPanel = () => {
    switch (selectedTab) {
      case 1:
        return renderClaim();
      case 2:
        return renderPackage();
      case 3:
        return renderCredit();
      default:
        return renderClaim();
    }
  };

  const renderClaim = () => {
    if (selectedClaim == null || selectedClaim.allocations.length == 0) {
      return (
        <>
          <div className="section">Allocations</div>
          <div className="flex justify-center flex-col" style={{ height: "calc(100vh - 340px)" }}>
            <Illustration width={178} />
            <div className="t--sm t--upcase t--center mt--8 mb--8">No allocations</div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="section">Allocations</div>
        <PackageAllocationsTable data={selectedClaim.allocations} />
      </>
    );
  };

  const renderPackage = () => {
    if (selectedPackage == null || selectedPackage.allocations.length == 0) {
      return (
        <>
          <div className="section">Allocations</div>
          <div className="flex justify-center flex-col" style={{ height: "calc(100vh - 340px)" }}>
            <Illustration width={178} />
            <div className="t--sm t--upcase t--center mt--8 mb--8">No allocations</div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="section">Allocations</div>
        <ClaimAllocationsTable data={selectedPackage.allocations} />
      </>
    );
  };

  const renderCredit = () => {
    if (selectedCredit == null) {
      return (
        <>
          <div className="section">Package</div>
          <div className="flex justify-center flex-col" style={{ height: "calc(100vh - 340px)" }}>
            <Illustration width={178} />
            <div className="t--sm t--upcase t--center mt--8 mb--8">No selected credit</div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="section">Package</div>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Invoice Number</div>
          <div className="t--sm">{selectedCredit.package.invoice_number}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Package Number</div>
          <div className="t--sm">{selectedCredit.package.package_number}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Units</div>
          <div className="t--sm">{selectedCredit.package.units}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Date</div>
          <div className="t--sm">{selectedCredit.package.date}</div>
        </div>
        <div className="section">Allocations</div>
        <ClaimAllocationsTable data={selectedCredit.allocations} />
      </>
    );
  };

  return (
    <>
      <div className="body__head">
        <div className="container container--columns">
          <div className="body__head__title">Account Balances</div>
          <div className="body__head__subtitle">View product balances by manufacturer</div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Filters
            kind="invoice"
            filterCallback={setFilters}
            idFilterCallback={setIdFilter}
            defaultFilters={props.filters}
            filterLists={props.filterLists}
            mandatoryFilters={["entity"]}
            disabledFilters={{ date_range: true, submission: true }}
            download={download}
          />
        </div>
      </div>
      <div className="body__content">
        <div className="panel--menu">{renderCards()}</div>
        <div className="panel panel--pad panel--no-btm">
          {renderHeader()}
          {renderTabs()}
          {selected && renderSelectedTab()}
        </div>
        <div className="panel panel--pad panel--info panel--no-btm">{renderDetailsPanel()}</div>
      </div>
    </>
  );
};

export default PurchaseReport;
