import React, { useState, useEffect } from "react";
import Filters from "/components/shared/filters";
import Http from "../http";
import { stringify } from "qs";
import _ from "lodash";
import PurchaseDetails from "./purchase_details";
import PurchasesTable from "./tables/purchases_table";
import Notice from "../../shared/Notice";

const PurchaseReport = (props) => {
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState(props.filters);
  const [idFilter, setIdFilter] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);

    var params = stringify(filters);
    history.pushState(filters, "", "/purchases/reports?" + params);

    Http.newPostQuery(props.query_path, { ...filters, ids: idFilter }, controller.signal).then((res) => {
      setInvoices(res.purchases);
      setIsLoading(false);
    });

    return () => {
      controller.abort();
    };
  }, [filters, idFilter]);

  const download = () => {
    const url = props.download_path + "?" + stringify(filters);
    window.open(url, "_blank");
  };

  const reload = () => {
    setIsLoading(true);
    Http.newFetchQuery(props.query_path, filters).then((res) => {
      setInvoices(res.purchases);
      setIsLoading(false);
    });
  };

  const reverse = (path, newQuantity) => {
    setIsLoading(true);
    Http.postData(path, { quantity: newQuantity }).then((res) => {
      console.log(res);
      if (res.success) {
        setNotice({
          kind: "success",
          open: true,
          message: res.success,
        });
        setSelectedLineItem(res.purchase);
      } else {
        setNotice({
          kind: "error",
          open: true,
          message: res.error,
        });
      }
      reload();
    });
  };

  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Purchase Report</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Filters
            kind="invoice"
            filterCallback={setFilters}
            idFilterCallback={setIdFilter}
            defaultFilters={props.filters}
            filterLists={props.filterLists}
            download={download}
          />
        </div>
      </div>
      <div className="body__content body__content">
        <div className="panel panel--pad panel--no-btm">
          <PurchasesTable
            invoice={invoices}
            setSelectedLineItem={setSelectedLineItem}
            isLoading={isLoading}
          />
        </div>
        <div className="panel panel--info panel--pad panel--no-btm">
          <PurchaseDetails line={selectedLineItem} reverse={reverse} />
        </div>
        <Notice details={notice} />
      </div>
    </>
  );
};

export default PurchaseReport;
